import moment from 'moment';
import { sites } from './whitelabel';

export const formatName = (first_name, last_name) => {
  return capitalizeFirst(first_name) + " " + capitalizeFirst(last_name);
};

export const capitalizeFirst = (word) => {
  return word[0].toUpperCase() + word.slice(1);
};

export const capitalizeWords = (words) =>
  words.split(" ").map(capitalizeFirst).join(" ");

export const formatDate = (datestring) => {
  const date = new Date(datestring);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const formatEuroDate = (datestring) => {
  return new Date(datestring).toLocaleDateString("en-GB").replaceAll("/", "-");
};

export const getDob = (dob) => {
  var a = moment();
  var b = moment(dob, 'MM-DD-YYYY');
  var age = moment.duration(a.diff(b));
  var years = age.years();
  return years;
};

export const getClient = () => {
  const hostname = window.location.hostname;
  const currentSite = sites.find(site => hostname.indexOf(site.url) > -1);
  return currentSite;
};

export const getMessageTime = () => {
  return moment().utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
}
