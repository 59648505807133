import { api_url } from "../config";
import { formatName } from "../utils";
import { createNotification } from "../components/Notifications";
import { store } from "../store";
import backend from "./backendInstance";
import { capitalizeWords } from "../utils";
import { STAFF_SUBMITTER } from "../constants";

export const createReferral = async (form_data, token) => {
  const state = store.getState();
  const { org_id, medical_group } = state.auth;

  const config = {
    method: "POST",
    url: `${api_url}/create_referral`,
    data: {
      patient_id: form_data.patient_id,
      hpsj_patient_id: form_data.member_id,
      patient_first: form_data.patient_first,
      patient_last: form_data.patient_last,
      patient_dob: form_data.dob,
      patient_plan: form_data.patient_plan || '',
      hpsj_select: form_data.hpsj_select || '',
      hpsj_plan_type: form_data.plan_type || '',
      diagnosis: form_data.diagnosis || [''],
      symptoms_reason_question: form_data.symptom_reason_question,
      servicing_provider: form_data.servicing_provider,
      pcp: form_data.pcp,
      pcp_npi: form_data.pcp_npi,
      pcp_id: form_data.pcp_id,
      specialty: form_data.specialty,
      order_id: form_data.order_id,
      patient_mobile: form_data.patient_mobile, //here onwards IEHP data
      patient_email: form_data.patient_email,
      contact_time: form_data.contact_time,
      coordinating_member: form_data.coordinating_member,
      guardian: form_data.guardian,
      staff: form_data.staff,
      primary_care_rovider: form_data.primary_care_provider,
      reason: form_data.reason,
      referral_status: form_data.referral_status,
      docs: form_data.docs, //upto here IEHP data
      org_id,
      medical_group,
    },
    headers: { auth: token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in CreateReferral", error);
    throw error;
  }
};

export const updateReferral = async (form_data, token) => {
  const state = store.getState();
  const { org_id, medical_group } = state.auth;

  const config = {
    method: "POST",
    url: `${api_url}/update_vrp_referral`,
    data: {
      patient_id: form_data.patient_id,
      hpsj_patient_id: form_data.member_id,
      patient_first: form_data.patient_first,
      patient_last: form_data.patient_last,
      patient_dob: form_data.dob,
      patient_plan: form_data.patient_plan || '',
      hpsj_select: form_data.hpsj_select || '',
      hpsj_plan_type: form_data.plan_type || '',
      diagnosis: form_data.diagnosis || [''],
      symptoms_reason_question: form_data.symptom_reason_question,
      servicing_provider: form_data.servicing_provider,
      pcp: form_data.pcp,
      pcp_npi: form_data.pcp_npi,
      pcp_id: form_data.pcp_id,
      specialty: form_data.specialty,
      order_id: form_data.order_id,
      org_id,
      medical_group,
      afya_id: form_data.afya_id,
      patient_mobile: form_data.patient_mobile, //here onwards IEHP data
      patient_email: form_data.patient_email,
      contact_time: form_data.contact_time,
      coordinating_member: form_data.coordinating_member,
      guardian: form_data.guardian,
      staff: form_data.staff,
      primary_care_rovider: form_data.primary_care_provider,
      reason: form_data.reason,
      referral_status: form_data.referral_status,
      appointment_date: form_data.appointment_date,
      notes: form_data.notes,
      scheduling_status: form_data.scheduling_status,
      docs: form_data.docs, //upto here IEHP data
    },
    headers: { auth: token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in updateReferral", error);
    throw error;
  }
};

export const findPCPs = async (input, token) => {
  const state = store.getState();
  const { org_id } = state.auth;
  const config = {
    method: "POST",
    url: `${api_url}/search_providers`,
    data: {
      input: input,
      org_id: org_id,
      role_name: "Submitter Provider",
    },
    headers: { "Content-Type": "application/json", auth: token },
  };
  try {
    const res = await backend.request(config);
    return res.data.map((item) => ({
      label: formatName(item.first_name, item.last_name),
      value: JSON.stringify(item),
      ...item,
    }));
  } catch (error) {
    console.error("error in FindPCPs", error);
    return [];
  }
};

export const getReferrals = async (token) => {
  const state = store.getState();
  const { org_id, role, medical_group } = state.auth;
  const { specFilter, statusFilter, clinicFilter, patientFilter, PCPFilter } =
    state.referral;
  let close_codes = null;
  let referral_status = null;
  if (statusFilter === "All") referral_status = null;
  else if (statusFilter === "Needs Completion") {
    close_codes = ["In-Person Visit", "Video Visit"];
  } else if (statusFilter === "eCARE Completed") {
    close_codes = ["Consultation Complete"];
  } else {
    referral_status = statusFilter;
  }
  const config = {
    method: "POST",
    url: `${api_url}/referrals`,
    data: {
      medical_group: clinicFilter === "All" ? null : clinicFilter,
      specialty: specFilter === "All" ? null : specFilter,
      pcp_id: PCPFilter === "All" ? null : PCPFilter,
      patient_id: patientFilter === "All" ? null : patientFilter,
      referral_status,
      close_codes,
      org_id,
      role
    },
    headers: { auth: token },
  };

  if (role === STAFF_SUBMITTER) {
    config.data.medical_group = medical_group;
  }

  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in getReferrals", error);
  }
};

export const getReferral = async (afya_id) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/ref_get`,
    data: { afya_id },
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in getReferral", error);
    createNotification(
      "Something went wrong",
      "Referral could not be fetched"
    );
  }
};

export const sendFax = (fax_number, media_url, auth_token) => {
  const config = {
    method: "POST",
    url: `${api_url}/send_fax`,
    data: { fax_number, media_url },
    headers: { auth: auth_token },
  };
  try {
    const result = backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in sendFax", error);
  }
};

export const findSpec = async (input, token) => {
  const state = store.getState();
  const { org_id } = state.auth;
  const config = {
    method: "POST",
    url: `${api_url}/search_specialties`,
    data: {
      input: input,
      org_id: org_id,
    },
    headers: { auth: token },
  };
  try {
    const res = await backend.request(config);
    return res.data.map((item) => ({
      label: item.name,
      value: JSON.stringify(item),
    }));
  } catch (error) {
    console.error("error in findSpec", error);
    return [];
  }
};

export const updateStatus = async (afya_id, token) => {
  const config = {
    method: "POST",
    url: `${api_url}/update_status`,
    data: { afya_id, status: "eCARE In Progress" },
    headers: { auth: token },
  };
  try {
    await backend.request(config);
  } catch (error) {
    console.error("error in updateStatus", error);
    createNotification(
      "Something went wrong",
      "Referral status could not be updated"
    );
  }
};

export const getAllSpecs = async () => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "GET",
    url: `${api_url}/referral_specs`,
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    createNotification(
      "Something went wrong",
      "Could not get specialties list"
    );
    console.error("error in getSpecOptions", error);
    return [];
  }
};

export const getAllClinics = async () => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "GET",
    url: `${api_url}/referral_clinics`,
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data.map((clinic) => ({
      value: clinic,
      label: capitalizeWords(clinic),
    }));
  } catch (error) {
    createNotification("Something went wrong", "Could not get clinics list");
    console.error("error in getSpecOptions", error);
    return [];
  }
};

export const findReferralUsers = async (input, type) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/search_referral_users`,
    data: {
      input: input,
      type: type,
    },
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  } catch (error) {
    console.error("error in findReferralUsers", error);
    return [];
  }
};

export const isDoctorAvailable = async (requestBody) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/search_provider_in_org`,
    data: requestBody,
    headers: { auth: auth_token },
  };
  try {
    const resp = await backend.request(config);
    return resp.data;
  } catch (error) {
    console.error("error in isDoctorAvailable", error);
    return { isdoctor: 0 };
  }
};

export const sendSMS = async (mobile) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/send_sms`,
    data: { mobile },
    headers: { auth: auth_token },
  };
  try {
    await backend.request(config);
  } catch (error) {
    console.error("error in sendSMS", error);
    createNotification(
      "Something went wrong",
      "Could not send SMS"
    );
  }
};

export const getStaffSubmitters = async (medical_group) => {
  const auth_token = store.getState().auth.token;
  const config = {
    method: "POST",
    url: `${api_url}/get_sub_staff_of_med_group`,
    data: { med_grp: medical_group },
    headers: { auth: auth_token },
  };
  try {
    const result = await backend.request(config);
    return result.data;
  } catch (error) {
    console.error("error in getStaffSubmitters", error);
    createNotification(
      "Something went wrong",
      "Staff submitters could not be fetched"
    );
  }
};