import { Checkbox, CheckboxGroup, createStyles, Select, Textarea, TextInput } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates"
import { useDebouncedValue } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import moment from "moment/moment";

import { findPatient, getStaffSubmitters, getUser, sendSMS } from "../../API";
import { OTHER } from "../../constants";
import { createNotification } from "../Notifications";

const countryCode = process.env.REACT_APP_COUNTRY_CODE;

const useStyles = createStyles((theme, _params, getRef) => ({
  input: {
    width: "300px",
    marginLeft: "5.5rem",
    marginTop: '1rem'
  },
  checkboxGroup: {
    '& .mantine-Group-root': {
      display: 'flex',
      flexDirection: 'column'
    },
    '& .mantine-Group-child': {
      alignSelf: 'flex-start'
    }
  },
  label: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '500',
    color: '#212529',
    lineHeight: '1.55',
    fontFamily: 'Roboto,Open Sans,Arial',
    width: '20rem',
    marginTop: '1rem',
    marginLeft: '5.5rem'
},
  phoneInput: {
    boxSizing: 'border-box',
    minHeight: '36px',
    padding: '0 12px',
    border: '1px solid #ced4da',
    width: "300px",
    marginLeft: "5.5rem",
    marginTop: '3px',
    outline: 'none',
    fontFamily: 'Roboto',
    borderRadius: '4px',
    transition: 'border-color 100ms ease 0s',

    '&:focus': {
      border: '1px solid rgb(25,113,194)'
    }
  }

}));

const getDate = (value) => {
    const dateSplit = value.toLocaleDateString("en-GB").split('/');
    const date = dateSplit[0];
    const month = dateSplit[1];
    const year = dateSplit[2];
    return `${month}-${date}-${year}`;
};

const contactTimes = [
  '7am-12pm', '12pm-6pm', '6pm-10pm'
];

const schedulingStatus = [
  {label:'Scheduled', value: 'scheduled'},
  {label: 'Visit occurred', value: 'visit occurred'}
];


const PatientForm = ({form, activeReferral}) => {
  const { classes } = useStyles();
  const { token:auth_token, role, medical_group } = useSelector((state) => state.auth);

  const [names, setNames] = useState([]);
  const [patientPhone, setPatientPhone] = useState(null);
  const [patientsData, setPatientsData] = useState({});
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [staffSubmitters, setStaffSubmitters] = useState([]);
  const [ debouncedDob ] = useDebouncedValue(form.values.dob, 500);

  const isReferralActive = () => {
    return Object.keys(activeReferral).length > 0 ? true : false;
  };

  useEffect(() => {
    const getPatient = async () => {
      const patient = await getUser(activeReferral.patient_id, auth_token);
      setSelectedPatient(patient);
    };

    if (isReferralActive()) {
      form.setFieldValue("dob", new Date(activeReferral.patient_dob));
      form.setFieldValue("contact_time", activeReferral.contact_time);
      form.setFieldValue("coordinating_member", activeReferral.coordinating_member);
      form.setFieldValue("primary_care_provider", activeReferral.primary_care_provider);
      form.setFieldValue("reason", activeReferral.reason);
      form.setFieldValue("guardian", activeReferral.guardian);
      form.setFieldValue("scheduling_status", activeReferral.scheduling_status);
      form.setFieldValue("notes", activeReferral.notes);
      setPatientPhone(activeReferral.patient_mobile);
      getPatient();

      if (activeReferral.appointment_date && activeReferral.appointment_date.trim() !== '') {
        const aptDateAndTime = activeReferral.appointment_date.split(' ');
        if (aptDateAndTime[0]) {
          form.setFieldValue("apt_date", new Date(aptDateAndTime[0]));
        }
        if (aptDateAndTime[1]) {
          const time = aptDateAndTime[1].split(':');
          const apt_time = moment().set('hour', time[0]).set('minute', time[1]).toString();
          form.setFieldValue("apt_time", new Date(apt_time));
        }
      }
    }
  }, [activeReferral]);

  useEffect(() => {
    const fetchStaffSubmitters = async () => {
      try {
        const data = await getStaffSubmitters(medical_group);
        const staffSubmitterOptions = [];
        data.forEach((obj) => {
          staffSubmitterOptions.push({
            label: obj.first_name + " " + obj.last_name,
            value: obj.user_id
          });
        });
        setStaffSubmitters(staffSubmitterOptions);

      } catch (error) {
        console.log(error);
      }
    };
    if (medical_group) {
      fetchStaffSubmitters();
    }
  }, []);

  useEffect(() => {
    if(isReferralActive()) {
      form.setFieldValue("staff", activeReferral.staff);
    }
  }, [staffSubmitters]);

  useEffect(() => {
    const dob = debouncedDob
                ? typeof debouncedDob === 'object' ? getDate(debouncedDob) : ""
                : "";
    const getPatients = async () => {
      const { nameOptions, allPatientsData } = await findPatient(
        dob,
        "",
        auth_token
      );
      setNames(nameOptions);
      setPatientsData(allPatientsData);
    };

    if (dob && role !== OTHER) {
      getPatients();
    }
  }, [debouncedDob]);

  let patientGender = '';

  if (selectedPatient) {
    patientGender = selectedPatient.gender === 1 ? 'Male' : 'Female';
  }

  const onMobileCheckboxChange = async (e) => {
    if (e.target.checked) {
      const phoneNumber = form.values.patient_mobile.trim();
      if (!phoneNumber || (phoneNumber.length !== 13 && phoneNumber.length !== 12)) {
        createNotification(
          "Error",
          "Invalid mobile number"
        );
        return;
      }
      try {
        await sendSMS(form.values.patient_mobile);
        createNotification(
          " ",
          "Text message sent",
          "SUCCESS"
        );
      } catch (error) {}
    }
  };

  const onEmailCheckboxChange = (e) => {
    if (e.target.checked) {

    }
  };

  const keyDownHandler = (e) => {
    if(e.keyCode == 13) {
        e.preventDefault();
        return;
    }
  };

  let doShowExtraFields = false;

  if (isReferralActive() && activeReferral.current_status === 'Enrolled') {
    doShowExtraFields = true;
  }

  return (
    <>
        <DatePicker
            initiallyOpened={false}
            className={classes.input}
            label="Date of Birth"
            locale="en"
            allowFreeInput
            required
            disabled={isReferralActive()}
            onKeyDown={keyDownHandler}
            {...form.getInputProps("dob")}
        />
        { isReferralActive() ? (
            <TextInput
            className={classes.input}
            label="Name"
            disabled={true}
            value={activeReferral.patient_name}
          />
          ) : ( <Select
            className={classes.input}
            label="Name"
            searchable={true}
            disabled={!form.values.dob}
            data={names}
            clearable
            onChange={(value) => {
              if (value) {
                const patient = patientsData.find(patient => patient.user_id === value);
                setSelectedPatient(patient);
                form.setFieldValue("patient_id", patient.user_id);
                form.setFieldValue("patient_name", patient.first_name + " " + patient.last_name);
              }
            }}
        />)}
        <TextInput
            className={classes.input}
            label="Sex"
            disabled={true}
            value={patientGender}
        />
        <TextInput
            className={classes.input}
            label="Member ID"
            disabled={true}
            value={selectedPatient?.member_identifier}
        />
        <Textarea
            className={classes.input}
            label="Address"
            disabled={true}
            rows={4}
            value={selectedPatient?.address}
        />
        <TextInput
            className={classes.input}
            label="City"
            disabled={true}
            value={selectedPatient?.city}
        />
        <TextInput
            className={classes.input}
            label="State"
            disabled={true}
            value={selectedPatient?.state}
        />
        <TextInput
            className={classes.input}
            label="Zip Code"
            disabled={true}
            value={selectedPatient?.zipcode}
        />
        <TextInput
            className={classes.input}
            label="Country"
            disabled={true}
            value={selectedPatient?.country}
        />
        <label className={classes.label}>Enter Mobile Phone</label>
        <PhoneInput
          withCountryCallingCode
          international
          country={countryCode}
          className={classes.phoneInput}
          value={patientPhone}
          onKeyDown={keyDownHandler}
          onChange={(value) => {
            form.setFieldValue("patient_mobile", value);
          }}
        />
        <Checkbox
          onChange={onMobileCheckboxChange}
          styles={{label: {fontSize: '13px', color: '#64748b'}}}
          style={{marginTop: '0.5rem', marginLeft: '5.5rem'}}
          label="Send initial text message"/>
        <TextInput
            style={{}}
            label="Enter Email Address"
            disabled
            className={classes.input}
            onKeyDown={keyDownHandler}
            placeholder="coming soon"
        />
        <Checkbox
          styles={{label: {fontSize: '13px', color: '#64748b'}}}
          style={{marginTop: '0.5rem', marginLeft: '5.5rem'}}
          disabled
          label="Send initial email message"/>
        <CheckboxGroup
          className={classes.input}
          label="Best time of day to contact"
          classNames={{root: classes.checkboxGroup}}
          value={form.values.contact_time}
          onChange={(value) => form.setFieldValue("contact_time", value)}
        >
          {contactTimes.map((time, index) => {
            return (
              <Checkbox value={time} label={time} key={index}/>
            );
          })}
        </CheckboxGroup>
        <Select
          className={classes.input}
          label="Patient or Family Coordinating"
          data={[
            { value: 'patient', label: 'Patient' },
            { value: 'family', label: 'Family' },
          ]}
          {...form.getInputProps("coordinating_member")}
        />
        <TextInput
          className={classes.input}
          label="Guardian Last Name, First Name (if applicable)"
          onKeyDown={keyDownHandler}
          {...form.getInputProps("guardian")}
        />
        <Select
          className={classes.input}
          label="Hospital Staff contact person (Receive closed loop communications/notifications)"
          data={staffSubmitters}
          {...form.getInputProps("staff")}
        />
        <TextInput
          className={classes.input}
          label="Patient's Primary Care Provider"
          onKeyDown={keyDownHandler}
          {...form.getInputProps("primary_care_provider")}
        />
        <Textarea
            className={classes.input}
            required
            label="Reasons for Referral"
            rows={4}
            {...form.getInputProps("reason")}
        />
        {doShowExtraFields && (
          <>
            <DatePicker
              initiallyOpened={false}
              className={classes.input}
              label="Appointment Date"
              locale="en"
              allowFreeInput
              onKeyDown={keyDownHandler}
              {...form.getInputProps("apt_date")}
            />
            <TimeInput
              className={classes.input}
              label="Appointment Time"
              {...form.getInputProps("apt_time")}
            />
            <Select
              className={classes.input}
              label="Scheduling Status"
              data={schedulingStatus}
              {...form.getInputProps("scheduling_status")}
            />
            <Textarea
              className={classes.input}
              label="Notes"
              rows={4}
              {...form.getInputProps("notes")}
            />
          </>
        )}
    </>
  )
}

export default PatientForm;