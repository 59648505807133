import { useDispatch, useSelector } from "react-redux";
import { PatientForm } from "./PatientForm";
import { ProviderForm } from "./ProviderForm";
import { FaxView } from "./FaxView";
import { useState, useEffect } from "react";
import Redirect from "./Redirect";
import { resetFormData } from "./referralSlice";
import { createStyles, Button, Group, Modal } from "@mantine/core";
import { TableSort } from "./TableSort";
import { CirclePlus } from "tabler-icons-react";
import SpecialtySelect from "./SpecialtySelect";
import ClinicSelect from "./ClinicSelect";
import PCPSelect from "./PCPSelect";
import PatientSelect from "./PatientSelect";
import StatusSelect from "./StatusSelect";
import PatientEnrollment from "../IEHP";
import { getClient } from "../../utils";

const useStyles = createStyles((theme, _params, getRef) => ({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "#EFEFEF",
    justifyContent: "center",
    padding: "0px",
    margin: "0px",
  },
  controlRow: {
    width: "100%",
    height: "80px",
    margin: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  filterRow: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  filters: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  button: {
    backgroundColor: "#4A7CBD",
    marginTop: "2.2em",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: theme.fn.darken("#4A7CBD", 0.05),
    },
  },
}));

export const Dashboard = () => {
  const isLive = useSelector((state) => state.referral.patientInfo).live_status;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const formPage = useSelector((state) => state.referral.formPage);
  const clientSite = getClient();
  const [opened, setOpened] = useState(false);

  let page = null;

  let modalSize = 650;
  let closeModalOnOutsideClick = true;

  if (clientSite.client === 'iehp') {
    modalSize = '30rem';
    closeModalOnOutsideClick = false;
    page = (<div style={{height: '35rem'}}>
      <PatientEnrollment setOpened={setOpened}/>
    </div>)
  } else {
    page = <PatientForm />;
    if (formPage === 1) {
      page = <ProviderForm />;
    } else if (formPage === 2) {
      if (isLive) page = <Redirect setModal={setOpened} />;
      else page = <FaxView setModal={setOpened} />;
    }
  }

  return (
    <div className={classes.container}>
      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
          dispatch(resetFormData());
        }}
        size={modalSize}
        padding={0}
        withCloseButton={false}
        centered
        closeOnClickOutside={closeModalOnOutsideClick}
      >
        {page}
      </Modal>
      <Group direction="column" position="center" style={{ width: "90%" }}>
        <div className={classes.controlRow}>
          <div className={classes.filterRow}>
            <div className={classes.filters}>
              <SpecialtySelect />
              <ClinicSelect />
              <PatientSelect />
              <PCPSelect />
              <StatusSelect />
            </div>
          </div>
          <Button
            leftIcon={<CirclePlus size={23} />}
            className={classes.button}
            onClick={async () => {
              setOpened(true);
            }}
          >
            New Referral
          </Button>
        </div>
        <TableSort setModal={setOpened} />
      </Group>
    </div>
  );
};
