import axios from "axios";
import moment from 'moment'

import { store } from "../store";
import { capitalizeFirst, formatEuroDate, formatName, getDob } from "../utils";
import { base_url, api_url } from "../config";
import { createNotification } from "../components/Notifications";
import backend from "./backendInstance";

export const findPatient = async (dob, key, token) => {
  const state = store.getState();
  const { org_id } = state.auth;
  const config = {
    method: "POST",
    url: `${base_url}/user_management/search`,
    data: { dob, key, org_id },
    headers: { "Content-Type": "application/json", auth: token },
  };
  try {
    const result = await backend.request(config);
    const { data } = result;
    if (data.length === 0) return { nameOptions: [], patientsData: {} };
    const nameOptions = [];
    const patientsData = {};
    for (let patient of data) {
      const nameOption = {
        label: patient.first_name + " " + patient.last_name,
        value: patient.user_id,
      };
      nameOptions.push(nameOption);
      const patientData = {
        dob: patient.dob,
        first_name: patient.first_name,
        last_name: patient.last_name,
        member_id: patient.member_identifier,
        agora_id: patient.agora_id,
        sex: patient.gender === 0 ? "Female" : "Male",
      };
      patientsData[patient.user_id] = patientData;
    }
    return { nameOptions, patientsData, allPatientsData: data };
  } catch (error) {
    console.error("error in findPatient", error);
  }
};

export const getPatientId = async (dob, key, token) => {
  const state = store.getState();
  const { org_id } = state.auth;
  const config = {
    method: "POST",
    url: `${base_url}/user_management/search`,
    data: { dob, key, org_id },
    headers: { auth: token },
  };
  try {
    const result = await backend.request(config);
    const { data } = result;
    if (data.length === 0) return null;
    return data[0].user_id;
  } catch (error) {
    console.error("error in getPatientId", error);
  }
};

export const findICDCodes = async (input) => {
  const url = new URL(
    "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search"
  );
  url.searchParams.append("sf", "code,name");
  url.searchParams.append("terms", input);
  url.searchParams.append("maxList", "80");
  try {
    const res = await axios.get(url.toString());
    return res.data[3].map((item) => {
      const option = {};
      const value = item.reduce((code, desc) => code + " " + desc);
      option.value = value;
      option.label = value;
      return option;
    });
  } catch (error) {
    console.error("error in getICDCodes", error);
  }
};

export const addPatient = async (
  first_name,
  last_name,
  dob,
  sex,
  member_id,
  token
) => {
  const state = store.getState();
  const { org_id } = state.auth;
  const gender = sex === "Female" ? 0 : 1;
  const data = {
    first_name,
    last_name,
    dob,
    email: "",
    mobile: "",
    gender,
    member_identifier: member_id,
    org_id: org_id,
  };
  try {
    const config = {
      url: `${base_url}/user_management/add_patients`,
      method: "POST",
      data,
      headers: { auth: token },
    };
    const res = await backend.request(config);
    return res.data;
  } catch (error) {
    console.error("Error in addPatient method", error);
    throw error;
  }
};

export const signIn = async (email, password) => {
  const config = {
    method: "POST",
    url: `${base_url}/user_management/self_login`,
    data: { email, password, force_login: 1 },
  };
  try {
    const { data } = await backend.request(config);
    return data;
  } catch (error) {
    throw error;
  }
};

export const signOut = async (id) => {
  const config = {
    method: "POST",
    url: `${base_url}/user_management/logout`,
    data: { id },
  };
  try {
    const { data } = await backend.request(config);
    return data;
  } catch (error) {
    console.error("Error in signOut method", error);
    createNotification("A problem occured", "The user could not be logged out");
  }
};

export const createChatSession = async (
  ICD,
  patient_agora_id,
  pcp_agora_id,
  clinical_question,
  pcp_id,
  patient_id,
  specialty,
  afya_id,
  auth_token
) => {
  const state = store.getState();
  const { org_id } = state.auth;
  const config = {
    method: "POST",
    url: `${base_url}/chat/create_session2`,
    data: {
      CPT: [],
      ICD,
      agora_id_of_owner_id_1: pcp_agora_id,
      agora_id_of_owner_id_2: patient_agora_id,
      clinical_question,
      owner_id_1: pcp_id,
      owner_id_2: patient_id,
      patient_agora_id: patient_agora_id,
      patient_id,
      specialty,
      org_id,
    },
    headers: { auth: auth_token },
  };
  try {
    const { data } = await backend.request(config);
    const session_id = data.id;
    const res = await backend.request({
      method: "POST",
      url: `${base_url}/user_management/auto_assign`,
      data: { specialty, user_id: pcp_id, session_id, org_id },
      headers: { auth: auth_token },
    });
    await backend.request({
      method: "POST",
      url: `${api_url}/update_session_id`,
      data: { session_id, afya_id },
      headers: { auth: auth_token },
    });

    const request_payload = {
      from_id: res.data['owner1.user_id'],
      from_name: `${res.data['owner1.first_name']} ${res.data['owner1.last_name']}`,
      img_url: res.data['owner1.image_url'],
      link: "",
      session_id: res.data.id,
      speciality: res.data['spec_master.name'],
      time: moment().utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
      updatedTime: moment().format('MMM-DD-YYYY, hh:mm A'),
    };

    const message1 = `This chat has been assigned to ${res.data['owner2.first_name']} ${res.data['owner2.last_name']} (${res.data['owner2.spec_master.name']}) and the provider will respond to this chat after they've reviewed`;
    const message2 = `RE:${formatName(res.data['patient.first_name'], res.data['patient.last_name'])}, ${res.data['patient.gender'] === 0 ? 'Female' : 'Male'},  ${getDob(res.data['patient.dob']) + ' ' + 'Years Old'}, ${capitalizeFirst(res.data.clinical_question)}`;

    request_payload.datatype = 'NOTES';
    request_payload.message = message1;
    await createChat(request_payload, auth_token);

    request_payload.datatype = 'TEXT';
    request_payload.message = message2;
    await createChat(request_payload, auth_token);
  } catch (error) {
    console.error("Error in createChatSession method", error);
    createNotification(
      "A problem occured",
      "The chat session could not be created"
    );
  }
};

export const createChat = async (data, auth_token) => {
  const config = {
    method: "POST",
    url: `${base_url}/chat/chat_datas_trans`,
    data,
    headers: {
      auth: auth_token
    }
  };
  try {
    await backend.request(config);
  } catch (error) {
    console.error("Error in createChat method", error);
    createNotification("A problem occured", "The chat could not be created");
  }
};

export const getProviderOrgList = async (provider, auth_token) => {
  const config = {
    method: "POST",
    url: `${base_url}/user_management/provider_org_list2`,
    data: {
      provider
    },
    headers: {
      auth: auth_token
    }
  };
  try {
    const { data } = await backend.request(config);
    return data;
  } catch (error) {
    console.error("Error in getProviderOrgList method", error);
    createNotification("A problem occured", "Could get providers org list");
  }
};

export const getProviderRoleList = async (provider, auth_token) => {
  const config = {
    method: "POST",
    url: `${base_url}/user_management/provider_role_list2`,
    data: {
      provider
    },
    headers: {
      auth: auth_token
    }
  };
  try {
    const { data } = await backend.request(config);
    return data;
  } catch (error) {
    console.error("Error in getProviderRoleList method", error);
    createNotification("A problem occured", "Could get providers role list");
  }
};

export const getUser = async (user_id, auth_token) => {
  const config = {
    method: "POST",
    url: `${base_url}/user_management/view_users`,
    data: {
      user_id
    },
    headers: {
      auth: auth_token
    }
  };
  try {
    const { data } = await backend.request(config);
    return data;
  } catch (error) {
    console.error("Error in getUser method", error);
    createNotification("A problem occured", "Could get user details");
  }
};

export * from "./referrals";
