import {
    createStyles,
    Title,
  } from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { CircleCheck } from "tabler-icons-react";
import * as Yup from "yup";
import { useForm, yupResolver } from "@mantine/form";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useRef } from "react";
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader'
import { uploadBytes, getDownloadURL, ref as strRef } from "firebase/storage";

import PatientForm from "./PatientForm";
import deleteIcon from '../../assets/x.png';
import {storage} from '../../config/firebase';
import { getMessageTime } from "../../utils";
import { createReferral, updateReferral } from "../../API";
import { createNotification } from "../Notifications";
import { resetFormData } from "../Dashboard/referralSlice";
import CustomScrollbars from '../../components/CustomScrollbars';

const schema = Yup.object().shape({});

const useStyles = createStyles((theme, _params, getRef) => ({
    btnConatiner: {
        marginTop: '2rem',
        paddingLeft: '4rem',
        button: {
            marginLeft: '1rem',
            padding: '1px 12px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: '500',
            letterSpacing: '.25px',
            lineHeight: '36px',
            width: '6rem',
            color: '#fff'
        },
        '& .submit': {
            backgroundColor: 'rgb(0 145 0)'
        },
        '& .save': {
            backgroundColor: '#17a2b8'
        },
        '& .cancel': {
            backgroundColor: 'rgb(217, 83, 79)'
        }
    },
    close: {
        position: 'fixed',
        top: '10px',
        right: '4px',
        fontSize: '39px',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        color: '#a19c9c'
    },
    label: {
        display: 'inline-block',
        fontSize: '14px',
        fontWeight: '500',
        color: '#212529',
        marginBottom: '4px',
        lineHeight: '1.55',
        fontFamily: 'Roboto,Open Sans,Arial',
        width: '20rem',
        marginTop: '1rem',
        marginLeft: '5.5rem'
    },
    dropzone: {
        minHeight: '100px',
        width: '300px',
        marginLeft: '5.5rem',
        position: 'relative',
        backgroundColor: '#F9F8FC',
        border: '1px solid #EAEAEA',
      },
    inputLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        fontSize: '16px',
        fontWeight: '600',
        color: '#2484ff',
        cursor: 'pointer',
        backgroundColor: '#F9F8FC',
        border: '1px solid #EAEAEA',
    },
    inputLabelWithFiles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-start',
        padding: '0 14px',
        minHeight: '32px',
        backgroundColor: '#E6E6E6',
        color: '#2484FF',
        border: 'none',
        fontFamily: '\'Helvetica\', sans-serif',
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: '600',
        marginTop: '2.5rem',
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        cursor: 'pointer',
    },
    file: {
        backgroundColor: '#FFF',
        border: '1px solid #EAEAEA',
        padding: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        button: {
            backgroundImage: `url(${deleteIcon})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#faf7fc00',
            width: '2rem',
            height: '2rem',
            border: 'transparent',
            cursor: 'pointer'
        }
    },
    attachedFiles: {
        width: '300px',
        marginLeft: '5.5rem',
        label: {
            marginLeft: '0'
        },
        '.docConatiner': {
            '.seperator': {
                width: '100%',
                height: '1px',
                marginBottom: '6px',
                background: '#a5a5a5'
            }
        },
        '.doc': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '14px',
            fontWeight: '500',
            color: '#a5a5a5',
            lineHeight: '1.55',
            fontFamily: 'Roboto,Open Sans,Arial',
            a:{
                textDecoration: 'none',
                cursor: 'pointer'
            },
        },
    }
}));

const trimFileName = (name) => {
    if (name) {
        const extension = name.split('.')[1];
        return name.length > 26 ? `${name.substring(0, 20)}....${extension}` : name;
    }
    return name;
};

const getDate = (value) => {
    const dateSplit = value.toLocaleDateString("en-GB").split('/');
    const date = dateSplit[0];
    const month = dateSplit[1];
    const year = dateSplit[2];
    return `${month}-${date}-${year}`;
};
const Preview = ({fileWithMeta}) => {
    const { classes } = useStyles();
    const { file, meta, remove } = fileWithMeta;
    return (
      <div className={classes.file}>
        <span>{trimFileName(file.name)}</span>
        <button type='button' onClick={() => { remove() }}></button>
    </div>
    )
}

const NoDropzoneLayout = ({ previews, submitButton, input, files, dropzoneProps }) => {
    const { ref, className, style } = dropzoneProps
    return (
        <div ref={ref} className={className} style={style}>
        {previews}

        {input}

        {files.length > 0 && submitButton}
        </div>
    )
}

const showToastMessage = (title) => {
    showNotification({
        id: 'load-data',
        loading: true,
        title: title,
        autoClose: false,
        disallowClose: true,
      });
};
const updateToastMessage = (title) => {
    updateNotification({
        id: 'load-data',
        color: 'teal',
        title: title,
        icon: <CircleCheck />,
        autoClose: 3000,
      });
};

const PatientEnrollmentForm = ({setOpened}) => {
    const { classes } = useStyles();
    const ref = useRef();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { token:auth_token } = useSelector((state) => state.auth);
    const { activeReferral } = useSelector((state) => state.referral);
    let docDownloadUrls = [];

    const form = useForm({
        schema: yupResolver(schema),
        initialValues: {},
    });

    const isReferralActive = () => {
        return Object.keys(activeReferral).length > 0 ? true : false;
    };

    const uploadFile = async (file, meta) => new Promise(async (res, rej) => {
        try {
            const ramdonName = getMessageTime() + '_' + meta.name;
            const storageRef = strRef(storage, `/vrp_files/${ramdonName}`);
            uploadBytes(storageRef, file).then(async (snapshot) => {
                const url = await getDownloadURL(storageRef);
                res(url);
            });
        } catch (error) {
            rej(error);
        }
    });

    const uploadFiles = async () => {
        if (ref.current.files.length) {
            const files = ref.current.files;
            for (let file of files) {
                const url = await uploadFile(file.file, file.meta);
                docDownloadUrls.push({
                    name: file.meta.name,
                    url
                });
            }
        }
    };

    const getAppointmentDateAndTime = () => {
        let appointment_date = '';
        let appointment_time = '';

        if (form.values.apt_date) {
            appointment_date = getDate(form.values.apt_date);
        }
        if (form.values.apt_time) {
            const hrs = form.values.apt_time.getHours();
            const min = form.values.apt_time.getMinutes();
            appointment_time = `${hrs}:${min}`
        }
        return `${appointment_date} ${appointment_time}`;
    };

    const saveForm = async () => {
        if (!isReferralActive() && !form.values.patient_id) {
            createNotification(
                "Message",
                "Please select patient"
            );
            return;
        }
        showToastMessage('Saving Referral...');
        setIsSubmitting(true);
        docDownloadUrls = [];

        await uploadFiles();

        const formData = {
            patient_id: isReferralActive() ? activeReferral.patient_id : form.values.patient_id,
            patient_mobile: form.values.patient_mobile || '',
            // patient_email: values.patient_email,
            contact_time: form.values.contact_time || [],
            coordinating_member: form.values.coordinating_member || '',
            guardian: form.values.guardian || '',
            staff: form.values.staff || '',
            primary_care_provider: form.values.primary_care_provider || '',
            reason: form.values.reason || '',
            afya_id: activeReferral.afya_id
        };

        try {
            if (isReferralActive()) {
                formData.docs = [...activeReferral.docs, ...docDownloadUrls];
                formData.referral_status = activeReferral.current_status;
                if (activeReferral.current_status === 'Enrolled') {
                    formData.scheduling_status = form.values.scheduling_status || '';
                    formData.notes = form.values.notes || '';
                    formData.appointment_date = getAppointmentDateAndTime();
                }
                await updateReferral(formData, auth_token);
            } else {
                formData.docs = docDownloadUrls;
                formData.referral_status = 'In Progress';
                await createReferral(formData, auth_token);
            }
            updateToastMessage('Referral saved successfully');
            setOpened(false);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            createNotification(
                "Something went wrong",
                "Please try again later"
            );
        }
        setIsSubmitting(false);
        docDownloadUrls = [];
    };

    const onFormSubmit = async (values, event) => {
        if (!values.patient_mobile) {
            createNotification(
                "Message",
                "Please enter mobile phone"
            );
            return;
        }

        if (isReferralActive()) {
            if (!ref.current.files.length && !activeReferral.docs.length) {
                createNotification(
                    "Message",
                    "Please upload files"
                );
                return;
            }
        } else {
            if (!ref.current.files.length) {
                createNotification(
                    "Message",
                    "Please upload files"
                );
                return;
            }
        }

        showToastMessage('Submitting Referral...');
        setIsSubmitting(true);
        docDownloadUrls = [];

        await uploadFiles();

        const formData = {
            patient_id: values.patient_id,
            patient_mobile: values.patient_mobile,
            // patient_email: values.patient_email,
            contact_time: values.contact_time || [],
            coordinating_member: values.coordinating_member || '',
            guardian: values.guardian || '',
            staff: values.staff || '',
            primary_care_provider: values.primary_care_provider || '',
            reason: values.reason || '',
            referral_status: 'Enrolled'
        };

        try {
            if (isReferralActive()) {
                formData.afya_id = activeReferral.afya_id;
                formData.docs = [...activeReferral.docs, ...docDownloadUrls];
                await updateReferral(formData, auth_token);
            } else {
                formData.docs = docDownloadUrls;
                await createReferral(formData, auth_token);
            }
            updateToastMessage('Referral submitted successfully');
            setOpened(false);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            createNotification(
                "Something went wrong",
                "Please try again later"
            );
        }
        setIsSubmitting(false);
        docDownloadUrls = [];
    };

    let attachedFiles = null;
    if (isReferralActive() && activeReferral?.docs?.length) {
        attachedFiles = (<div className={classes.attachedFiles}>
            <label className={classes.label}>Uploaded Files</label>
                <div className="docConatiner">
                    {activeReferral.docs.map((doc, index) => {
                        return (<React.Fragment key={index}>
                            <div className="doc">
                                <span>{doc.name}</span>
                                <a href={doc.url} target="_blank">Preview</a>
                            </div>
                            { index !== activeReferral.docs.length - 1 && <div className="seperator"></div>}
                        </React.Fragment>);
                    })}
                </div>
           </div>);
    }

    let showSubmitBtn = true;

    if (isReferralActive() && activeReferral.current_status === 'Enrolled') {
        showSubmitBtn = false;
    }

    return (
        <form style={{padding: '20px 0', width: '100%'}} onSubmit={form.onSubmit(onFormSubmit)}>
            <Title
                order={3}
                align="center"
                style={{ color: "#4D7ABE" }}
                data-autofocus
            >
                IEHP QIA - Patient Enrollment
            </Title>
            <button
                className={classes.close}
                type="button"
                onClick={() => {
                    setOpened(false);
                    dispatch(resetFormData());
                }}
                >
                    &times;
            </button>
            <div style={{height: '30rem'}}>
                <CustomScrollbars>
                    <PatientForm form={form} activeReferral={activeReferral}/>
                    <label
                        className={classes.label}>
                        Please upload files (post discharge, visit notes, labs, etc)
                    </label>
                    <Dropzone
                        ref={ref}
                        LayoutComponent={NoDropzoneLayout}
                        PreviewComponent={Preview}
                        inputContent="Choose Files"
                        autoUpload={false}
                        classNames={{dropzone: classes.dropzone, inputLabel: classes.inputLabel, inputLabelWithFiles: classes.inputLabelWithFiles}}
                    />
                    {attachedFiles}
                    <div className={classes.btnConatiner}>
                        <button
                            type="button"
                            className="cancel"
                            style={{cursor: isSubmitting ? 'not-allowed' : 'pointer', marginLeft: showSubmitBtn ? '1rem' : '4rem'}}
                            disabled={isSubmitting}
                            onClick={() => {
                                setOpened(false);
                                setTimeout(() => {
                                    dispatch(resetFormData());
                                }, 500);
                            }}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="save"
                            style={{cursor: isSubmitting ? 'not-allowed' : 'pointer'}}
                            disabled={isSubmitting}
                            onClick={() => saveForm()}
                        >
                            Save
                        </button>
                        {showSubmitBtn && (
                            <button
                            type="submit"
                            className="submit"
                            style={{cursor: isSubmitting ? 'not-allowed' : 'pointer'}}
                            disabled={isSubmitting}
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </CustomScrollbars>
            </div>
        </form>
    )
}

export default PatientEnrollmentForm